import TemplateContents from './TemplateContents';

import * as THREE from "three";

import vs_copy from "./shaders/vs_copy.glsl";
import fs_copy from "./shaders/fs_copy.glsl";

import vs_copy_distortion from './shaders/vs_copy_distortion.glsl';
import fs_copy_distortion from './shaders/fs_copy_distortion.glsl';

import copyMap1 from '../img/top/hero_copy1.png';
import copyMap2 from '../img/top/hero_copy2.png';
import copyMap3 from '../img/top/hero_copy3.png';
import copyMap1SP from '../img/top/hero_copy1_sp.png';
import copyMap2SP from '../img/top/hero_copy2_sp.png';
import copyMap3SP from '../img/top/hero_copy3_sp.png';

import copyMap1Service from '../img/service/hero_copy1.png';
import copyMap1SPService from '../img/service/hero_copy1_sp.png';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();

        this.TEXURE_WIDTH = 2880;
        this.TEXURE_HEIGHT = 1600;
        this.TEXURE_WIDTH_SP = 828;
        this.TEXURE_HEIGHT_SP = 1430;

        this.alpha = 0;
        this.progress = 0.25;
        this.scale = 0.7;
        this.texture2Alpha = 0;
        this.texture3Alpha = 0;

        this.isPC = (this.sw >= this.pack.BP) ? true : false;
        this.isPastPC = this.isPC;
        this.copyTexture1 = new THREE.TextureLoader().load(copyMap1);
        this.copyTexture2 = new THREE.TextureLoader().load(copyMap2);
        this.copyTexture3 = new THREE.TextureLoader().load(copyMap3);
        this.copyTexture1SP = new THREE.TextureLoader().load(copyMap1SP);
        this.copyTexture2SP = new THREE.TextureLoader().load(copyMap2SP);
        this.copyTexture3SP = new THREE.TextureLoader().load(copyMap3SP);

        this.copyTexture1Service = new THREE.TextureLoader().load(copyMap1Service);
        this.copyTexture1SPService = new THREE.TextureLoader().load(copyMap1SPService);

        this.enabled = false;
        this.renderEnabled = false;
        this.replacedToImageCopy = false;
        this.skippedOpening = false;

        this.appendedImages = [];
    }

    setDom(){
        super.setDom();

        this.cCopyContainer = document.querySelector('.cCopyContainer');
    }

    initEvents(){
        super.initEvents();
    }

    //for debug
    setGUI(gui){
        this.settings = {
            progress: this.progress,
            scale: this.scale,
        }

        gui.add(this.settings, 'progress', 0, 1).onChange(value => {
            this.setCopyMatUniform('progress', value);
        });

        gui.add(this.settings, 'scale', 0, 10).onChange(value => {
            this.setCopyMatUniform('scale', value);
        });
    }

    setCopyMatUniform(name, value){
        this.copyMaterial.uniforms[name].value = value;
    }

    setCopy(target){
        this.scene = target.scene;
        this.renderer = target.renderer;
        this.initWebGL();
        this.initPostMeshes();
        this.initMeshes();
        this.initImgs();
    }

    initWebGL(){
        if(this.isPC){
            this.sizeW =　this.TEXURE_WIDTH
            this.sizeH = this.TEXURE_HEIGHT;
        }else{
            this.sizeW =　this.TEXURE_WIDTH_SP
            this.sizeH = this.TEXURE_HEIGHT_SP;
        }

        this.postScene = new THREE.Scene();
        let renderTargetParams = {
            minFilter:THREE.LinearFilter,
            stencilBuffer:false,
            depthBuffer:false,
        };
        this.postCamera = new THREE.OrthographicCamera( this.sizeW / - 2, this.sizeW / 2, this.sizeH / 2, this.sizeH / - 2, -10000, 10000 );
        this.renderTarget = new THREE.WebGLRenderTarget(this.sizeW, this.sizeH, renderTargetParams);
    }

    initPostMeshes() {
        let textureWidth, textureHeight, copyTexture1, copyTexture2, copyTexture3, copyTexture1Service;
        if(this.isPC){
            textureWidth = this.TEXURE_WIDTH;
            textureHeight = this.TEXURE_HEIGHT;
            copyTexture1 = this.copyTexture1;
            copyTexture2 = this.copyTexture2;
            copyTexture3 = this.copyTexture3;
            copyTexture1Service = this.copyTexture1Service;
        }else{
            textureWidth = this.TEXURE_WIDTH_SP;
            textureHeight = this.TEXURE_HEIGHT_SP;
            copyTexture1 = this.copyTexture1SP;
            copyTexture2 = this.copyTexture2SP;
            copyTexture3 = this.copyTexture3SP;
            copyTexture1Service = this.copyTexture1SPService;
        }

        let isTop = this.pack.isTop();

        let geometry = new THREE.PlaneGeometry(2, 2);
        let material = this.copyTextureMaterial = new THREE.ShaderMaterial({
            uniforms: {
                resolution: {type:'v2', value: new THREE.Vector2(this.sw, this.sh)},
                time: {type:'f', value: 0.0},
                textureResolution: {type:'v2', value: new THREE.Vector2(textureWidth, textureHeight)},
                uTexture1: { value: copyTexture1 },
                uTexture2: { value: copyTexture2 },
                uTexture3: { value: copyTexture3 },
                uTexture1Service: { value: copyTexture1Service },
                alphaTest: {type:'f', value: .5},
                alpha: {type:'f', value: 1},
                uTextureAlpha1: {type:'f', value: 1.},
                uTextureAlpha2: {type:'f', value: this.texture2Alpha},
                uTextureAlpha3: {type:'f', value: this.texture3Alpha},
                isTop: {type:'bool', value: isTop}
            },
            vertexShader: vs_copy,
            fragmentShader: fs_copy,
            depthTest: false, // no depth test
        });
        let plane = new THREE.Mesh(geometry, material);
        this.postScene.add(plane);
    }

    changeTextures(){
        let copyTexture1, copyTexture2, copyTexture3, copyTexture1Service;
        if(this.isPC){
            copyTexture1 = this.copyTexture1;
            copyTexture2 = this.copyTexture2;
            copyTexture3 = this.copyTexture3;
            copyTexture1Service = this.copyTexture1Service;
        }else{
            copyTexture1 = this.copyTexture1SP;
            copyTexture2 = this.copyTexture2SP;
            copyTexture3 = this.copyTexture3SP;
            copyTexture1Service = this.copyTexture1SPService;
        }

        let isTop = this.pack.isTop();

        this.copyTextureMaterial.uniforms.uTexture1.value = copyTexture1;
        this.copyTextureMaterial.uniforms.uTexture2.value = copyTexture2;
        this.copyTextureMaterial.uniforms.uTexture3.value = copyTexture3;
        this.copyTextureMaterial.uniforms.uTexture1Service.value = copyTexture1Service;
        this.copyTextureMaterial.uniforms.isTop.value = isTop;
    }

    initMeshes(){
        this.initCopyMesh();
    }

    initCopyMesh(){
        let textureWidth, textureHeight;
        if(this.isPC){
            textureWidth = this.TEXURE_WIDTH;
            textureHeight = this.TEXURE_HEIGHT;
        }else{
            textureWidth = this.TEXURE_WIDTH_SP;
            textureHeight = this.TEXURE_HEIGHT_SP;
        }

        let geometry = new THREE.PlaneGeometry( 2, 2);
        let material = this.copyMaterial = new THREE.ShaderMaterial({
            uniforms: {
                resolution: {type:'v2', value: new THREE.Vector2(this.sw, this.sh)},
                time: {type:'f', value: 0.0},
                progress: {type:'f', value: this.progress},
                scale: {type:'f', value: this.scale},
                textureResolution: {type:'v2', value: new THREE.Vector2(textureWidth, textureHeight)},
                uTexture: { value: this.renderTarget.texture },
                // uTexture: { type:'t', value: new THREE.TextureLoader().load(testMap) },
                alphaTest: {type:'f', value: .5},
                alpha: {type:'f', value: this.alpha},
            },
            vertexShader: vs_copy_distortion,
            fragmentShader: fs_copy_distortion,
            transparent: true,
            depthTest: false, // no depth test
        });

        let plane = new THREE.Mesh( geometry, material );
        plane.renderOrder = 1; // rendering first
        this.scene.add( plane );
    }

    initImgs(){
        if(this.pack.isTop()) this.initTopImgs();
        else this.initServiceImgs();
    }

    initTopImgs(){
        this.appendedImages = [];
        let pcImgs = [copyMap1, copyMap2, copyMap3];
        let spImgs = [copyMap1SP, copyMap2SP, copyMap3SP];
        for( let i = 0; i < 3; i++ ){
            let imgPC = document.createElement('img');
            let imgSP = document.createElement('img');
            imgPC.src = pcImgs[i];
            imgPC.classList.add('pc');
            imgSP.src = spImgs[i];
            imgSP.classList.add('sp');
            this.cCopyContainer.appendChild(imgPC);
            this.cCopyContainer.appendChild(imgSP);
            this.appendedImages.push(imgPC);
            this.appendedImages.push(imgSP);
        }
    }

    initServiceImgs(){
        this.appendedImages = [];
        let imgPC = document.createElement('img');
        let imgSP = document.createElement('img');
        imgPC.src = copyMap1Service;
        imgPC.classList.add('pc');
        imgSP.src = copyMap1SPService;
        imgSP.classList.add('sp');
        this.cCopyContainer.appendChild(imgPC);
        this.cCopyContainer.appendChild(imgSP);
        this.appendedImages.push(imgPC);
        this.appendedImages.push(imgSP);
    }

    resetImgs(){
        for( let i = 0, len = this.appendedImages.length; i < len; i++ ){
            let img = this.appendedImages[i];
            img.remove();
        }
    }

    leave(){
        let ease = 'quart.out';
        gsap.killTweensOf(this);
        gsap.to(this, .5, {alpha:0, ease:ease, onUpdate:()=>{
                this.copyMaterial.uniforms.alpha.value = this.alpha;
            }});
        this.cCopyContainer.classList.add('fadeOut');
    }

    start(isTransition){
        let delay = isTransition ? 0 : 1.2;
        //whole alpha
        let ease = 'quart.out';
        gsap.to(this, .5, {delay: delay, alpha:1, ease:ease, onUpdate:()=>{
                if(!this.skippedOpening) this.copyMaterial.uniforms.alpha.value = this.alpha;
            }});

        //individual alphas
        gsap.to(this, .75, {delay:delay + 0.25, texture2Alpha:1, ease:ease, onUpdate:()=>{
                this.copyTextureMaterial.uniforms.uTextureAlpha2.value = this.texture2Alpha;
            }});
        gsap.to(this, .75, {delay:delay + 0.5, texture3Alpha:1, ease:ease, onUpdate:()=>{
                this.copyTextureMaterial.uniforms.uTextureAlpha3.value = this.texture3Alpha;
            }});

        //distortion
        gsap.to(this, 2.8, {delay:delay, progress:0, ease:ease, onUpdate:()=>{
                this.copyMaterial.uniforms.progress.value = this.progress;
            }, onComplete:()=>{
                if(!this.skippedOpening) this.cCopyContainer.classList.add('show');
                this.copyMaterial.uniforms.alpha.value = 0;
                this.replacedToImageCopy = true;
                this.renderEnabled  = false;
            }});

        gsap.delayedCall(delay, ()=>{
            this.enabled = true;
            this.renderEnabled = true;
            eventBus.emit('initialCopyMotionStart');
        });

        gsap.delayedCall(delay + 2, ()=>{
            eventBus.emit('initialMotionEnd');
        });
    }

    restart(){

        this.cCopyContainer.classList.remove('show');
        this.cCopyContainer.classList.remove('fadeOut');

        this.resetImgs();
        this.initImgs();
        this.changeTextures();

        this.enabled = false;
        this.renderEnabled = false;
        this.replacedToImageCopy = false;
        this.skippedOpening = false;
        this.alpha = 0;
        this.progress = 0.25;
        this.texture2Alpha = 0;
        this.texture3Alpha = 0;
        this.copyTextureMaterial.uniforms.uTextureAlpha2.value = this.texture2Alpha;
        this.copyTextureMaterial.uniforms.uTextureAlpha3.value = this.texture3Alpha;
        this.start(true);
    }

    transit(value){
        if(!this.replacedToImageCopy) {
            let ease = 'quart.out';
            gsap.killTweensOf(this);
            gsap.to(this, .5, {alpha:0, ease:ease, onUpdate:()=>{
                this.copyMaterial.uniforms.alpha.value = this.alpha;
            }});
            this.skippedOpening = true;
        }

        if(!this.enabled) return;

        if(value === 'KV'){
            if(!this.cCopyContainer.classList.contains('show')) this.cCopyContainer.classList.add('show');
            this.cCopyContainer.classList.remove('fadeOut');
        }else if(value === 'section1-2' || value === 'overview' ){
            this.cCopyContainer.classList.remove('show');
        }else{
            this.cCopyContainer.classList.add('fadeOut');
        }
    }



    scrollHandler(){

    }

    enterframe(){

    }

    enterframeThinOut(){
        if(!this.renderEnabled) return;
        this.copyMaterial.uniforms.time.value += .02;

        this.renderer.setRenderTarget(this.renderTarget);
        this.renderer.render(this.postScene, this.postCamera);
        this.renderer.setRenderTarget(null);
    }

    executeResize() {
        super.executeResize();

        this.isPC = (this.sw >= this.pack.BP) ? true : false;
        if(this.isPC != this.isPastPC){
            if(this.copyTextureMaterial){
                if(this.isPC){
                    this.copyTextureMaterial.uniforms.textureResolution.value = new THREE.Vector2(this.TEXURE_WIDTH, this.TEXURE_HEIGHT);
                    this.copyTextureMaterial.uniforms.uTexture1.value = this.copyTexture1;
                    this.copyTextureMaterial.uniforms.uTexture2.value = this.copyTexture2;
                    this.copyTextureMaterial.uniforms.uTexture3.value = this.copyTexture3;
                }else{
                    this.copyTextureMaterial.uniforms.textureResolution.value = new THREE.Vector2(this.TEXURE_WIDTH_SP, this.TEXURE_HEIGHT_SP);
                    this.copyTextureMaterial.uniforms.uTexture1.value = this.copyTexture1SP;
                    this.copyTextureMaterial.uniforms.uTexture2.value = this.copyTexture2SP;
                    this.copyTextureMaterial.uniforms.uTexture3.value = this.copyTexture3SP;
                }
            }

            if(this.copyMaterial) {
                if(this.isPC){
                    this.copyMaterial.uniforms.textureResolution.value = new THREE.Vector2(this.TEXURE_WIDTH, this.TEXURE_HEIGHT);
                }else{
                    this.copyMaterial.uniforms.textureResolution.value = new THREE.Vector2(this.TEXURE_WIDTH_SP, this.TEXURE_HEIGHT_SP);
                }
            }
        }

        this.isPastPC = this.isPC;

        if(this.copyMaterial) {
            this.copyMaterial.uniforms.resolution.value = new THREE.Vector2(this.sw, this.sh);
        }
    }
}