import * as THREE from 'three';

export default class {
    constructor(texture, vs, fs, target){
        let renderTargetParams = {
            minFilter:THREE.LinearFilter,
            stencilBuffer:false,
            depthBuffer:false,
        };

        //Render to Texture RTT1
        this.scene = new THREE.Scene();

        //TODO
        this.imageW = texture.source.data.width;
        this.imageH = texture.source.data.height;

        this.rt = new THREE.WebGLRenderTarget(this.imageW, this.imageH, renderTargetParams);

        this.uniforms = {
            colorMap: { type: "t", value: texture },
            u_textureSize: { type: "v2", value: new THREE.Vector2( this.imageW, this.imageH ) },
            u_erode: { type: "f", value: target.erode },
            u_dilate: { type: "f", value: target.dilate },
            u_divisor: { type: "f", value: 1.0 },

            // first FBO gets an extra texture, for use with fs_rtt
            valueMap: { type: "t", value: this.rt.texture }
        };

        this.material = new THREE.ShaderMaterial({
            uniforms: this.uniforms,
            vertexShader: vs,
            fragmentShader: fs
        });

        this.camera = new THREE.OrthographicCamera( this.imageW / - 2, this.imageW / 2, this.imageH / 2, this.imageH / - 2, -10000, 10000 );

        this.geo = new THREE.PlaneGeometry( this.imageW, this.imageH );
        this.mesh = new THREE.Mesh( this.geo, this.material );
        this.mesh.position.z = -100;
        this.scene.add( this.mesh );

        //RTT2
        this.scene2 = new THREE.Scene();
        this.rt2 = new THREE.WebGLRenderTarget(this.imageW, this.imageH, renderTargetParams);
        this.uniforms2 = {
            colorMap: { type: "t", value: this.rt.texture },
            u_textureSize: { type: "v2", value: new THREE.Vector2( this.imageW, this.imageH ) },
            u_erode: { type: "f", value: target.erode },
            u_dilate: { type: "f", value: target.dilate },
            u_divisor: { type: "f", value: 1.0 },
        };
        this.material2 = new THREE.ShaderMaterial({
            uniforms: this.uniforms2,
            vertexShader: vs,
            fragmentShader: fs
        });

        this.camera2 = new THREE.OrthographicCamera( this.imageW / - 2, this.imageW / 2, this.imageH / 2, this.imageH / - 2, 1, 10000 );

        this.geo2 = new THREE.PlaneGeometry( this.imageW, this.imageH );
        this.mesh2 = new THREE.Mesh( this.geo2, this.material2 );
        this.mesh2.position.z = -100;
        this.scene2.add( this.mesh2 );

        let RTT = {
                image: texture,
                scene: this.scene,
                camera: this.camera,
                texture: this.rt,
                textureMat: this.material,
                scene2: this.scene2,
                camera2: this.camera2,
                texture2: this.rt2,
                textureMat2: this.material2
        };

        return RTT;
    }
}