import TemplateContents from './TemplateContents';
import * as THREE from "three";
import vs_scanline from "./shaders/vs_scanline.glsl";
import fs_scanline from "./shaders/fs_scanline.glsl";

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
        this.DEF_OPACITY = .09;
        this.opacity = this.DEF_OPACITY;
    }

    setDom(){
        super.setDom();
    }

    initEvents(){
        super.initEvents();
    }

    setScanLine(target){
        this.scene = target.scene;
        this.renderer = target.renderer;
        this.initMesh();
    }

    initMesh(){
        let geometry = new THREE.PlaneGeometry( 2, 2);

        let material = this.scanLineMaterial = new THREE.ShaderMaterial({
            uniforms: {
                resolution: {type:'v2', value: new THREE.Vector2(this.sw, this.sh)},
                time: {type:'f', value: 0.0},
                alphaTest: {type:'f', value: .5},
                alpha: {type:'f', value: this.opacity},
            },
            vertexShader: vs_scanline,
            fragmentShader: fs_scanline,
            transparent: true,
            blending: THREE.AdditiveBlending,
            depthTest: false, // no depth test
        });

        let plane = new THREE.Mesh( geometry, material );
        plane.renderOrder = 0; // rendering first
        this.scene.add( plane );
    }

    transit(value, cutIn){
        let opacity;
        if(value === 'KV' || value === 'section1' || value === 'section3'){
            opacity = this.DEF_OPACITY;
        }else{
            opacity = 0;
        }

        if(cutIn){
            this.scanLineMaterial.uniforms.alpha.value = this.opacity = opacity;
            this.pack.gl.render();
        }else{
            gsap.to(this, .3, {opacity: opacity, ease: 'quart.out', onUpdate:()=>{
                    this.scanLineMaterial.uniforms.alpha.value = this.opacity;
                }});
        }

    }

    start(){

    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){
        this.scanLineMaterial.uniforms.time.value += .23;
    }

    executeResize() {
        super.executeResize();
    }
}