import TemplateContents from './TemplateContents';

import * as THREE from "three";

import vs_distortion from './shaders/vs_distortion.glsl';
import fs_distortion from './shaders/fs_distortion.glsl';

import bgMap from '../img/top/bg.jpg';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();

        this.TEXURE_WIDTH = 2800;
        this.TEXURE_HEIGHT = 1600;

        this.isOpening = true;

        //背景冒頭
        this.waveBackgroundSpeed = .23;
        this.opacityBackground = 1;
        this.waveFrequency = 12;
        this.waveAmplitude = 2.3;
        this.waveStrength = 3.0;
    }

    setDom(){
        super.setDom();
    }

    initEvents(){
        super.initEvents();
    }

    //for debug
    setGUI(gui){
        const PROPS = {
            waveFrequency: this.waveFrequency,
            waveAmplitude: this.waveAmplitude,
            waveStrength: this.waveStrength,
        }

        gui.add(PROPS, 'waveFrequency', 0, 100).onChange(value => {
            this.setBackgroundMatUniform('waveFrequency', value);
        });

        gui.add(PROPS, 'waveAmplitude', 0, 100).onChange(value => {
            this.setBackgroundMatUniform('waveAmplitude', value);
        });
        gui.add(PROPS, 'waveStrength', 0, 10).onChange(value => {
            this.setBackgroundMatUniform('waveStrength', value);
        });
    }

    setBackgroundMatUniform(name, value){
        this.backgroundMaterial.uniforms[name].value = value;
    }

    setBackground(target){
        this.scene = target.scene;
        this.renderer = target.renderer;
        // this.initWebGL();
        this.initMeshes();
    }

    initWebGL(){
        this.sizeW = this.sizeH = 1024;
        this.postScene = new THREE.Scene();

        let renderTargetParams = {
            minFilter:THREE.LinearFilter,
            stencilBuffer:false,
            depthBuffer:false,
        };
        this.postCamera = new THREE.OrthographicCamera( this.sizeW / - 2, this.sizeW / 2, this.sizeH / 2, this.sizeH / - 2, -10000, 10000 );
        this.renderTarget = new THREE.WebGLRenderTarget(this.sizeW, this.sizeH, renderTargetParams);
    }

    initMeshes(){
        this.initBackgroundMesh();
    }

    initBackgroundMesh(){
        let geometry = new THREE.PlaneGeometry( 2, 2);

        let material = this.backgroundMaterial = new THREE.ShaderMaterial({
            uniforms: {
                resolution: {type:'v2', value: new THREE.Vector2(this.sw, this.sh)},
                time: {type:'f', value: 0.0},
                waveFrequency: {type:'f', value: this.waveFrequency},
                waveAmplitude: {type:'f', value: this.waveAmplitude},
                waveStrength: {type:'f', value: this.waveStrength},
                textureResolution: {type:'v2', value: new THREE.Vector2(this.TEXURE_WIDTH,this.TEXURE_HEIGHT)},
                uTexture: { value: new THREE.TextureLoader().load(bgMap) },
                alphaTest: {type:'f', value: .5},
                alpha: {type:'f', value: this.opacityBackground},
            },
            // side: THREE.DoubleSide,
            vertexShader: vs_distortion,
            fragmentShader: fs_distortion,
            transparent: true,
            depthTest: false, // no depth test
        });

        let plane = new THREE.Mesh( geometry, material );
        plane.renderOrder = -2; // rendering first
        this.scene.add( plane );
    }

    leave(){
        let ease = 'quart.out';
        gsap.killTweensOf(this);
        gsap.to(this, .5, {opacityBackground: 1, ease:ease, onUpdate:()=>{
                this.backgroundMaterial.uniforms.alpha.value = this.opacityBackground;
            }});
    }

    start(){
        //background
        let dr = 4;
        let ease = 'quart.inOut';
        gsap.to(this, dr, {waveBackgroundSpeed:.05, waveAmplitude:30, waveStrength:0, ease:ease, onUpdate:()=>{
            this.backgroundMaterial.uniforms.waveAmplitude.value = this.waveAmplitude;
            this.backgroundMaterial.uniforms.waveStrength.value = this.waveStrength;
        }});

        gsap.delayedCall(dr, ()=>{
            this.isOpening = false;
        });
    }

    transit(value, cutIn){
        let ease1 = 'quart.inOut';
        let ease2 = 'quart.out';
        let ease;
        let dr = 1.2;
        let opacity;

        if(!this.isOpening) gsap.killTweensOf(this);

        if(value === 'KV'){
            opacity = 1;
            ease = ease1;
        }else if(value === 'section1'){
            opacity = 1;
            ease = ease2;
        }else if(value === 'section1-2'){
            opacity = 0;
            ease = ease2;
        }else if(value === 'section2'){
            opacity = 0;
            ease = ease2;
        }else if(value === 'section3'){
            dr = 1.7;
            opacity = 1;
            ease = ease2;
        }

        if(cutIn) {
            gsap.killTweensOf(this);
            this.backgroundMaterial.uniforms.alpha.value = this.opacityBackground = opacity;
            this.pack.gl.render();
        }else{
            gsap.to(this, dr, {opacityBackground: opacity, ease:ease, onUpdate:()=>{
                    this.backgroundMaterial.uniforms.alpha.value = this.opacityBackground;
                }});
        }
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){
        this.backgroundMaterial.uniforms.time.value += this.waveBackgroundSpeed;
    }

    executeResize() {
        super.executeResize();
        if(this.backgroundMaterial) this.backgroundMaterial.uniforms.resolution.value = new THREE.Vector2(this.sw, this.sh);
    }
}