import TemplateContents from './TemplateContents';

import GUI from 'lil-gui';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import Flower from "./Flower";
import Background from "./Background";
import Copy from "./Copy";
import ScanLine from "./ScanLine";

import depthMap from '../img/top/img_flower_depth.jpg';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.enabledControls = false;

        // this.devicePixelRatio = window.devicePixelRatio || 1;
        // this.devicePixelRatio = Math.min(2, this.devicePixelRatio);

        this.canvasContainer = document.querySelector('.cCanvasBackground');
        this.canvas = document.createElement("canvas");
        this.canvasContainer.appendChild(this.canvas);
        this.sw = window.innerWidth;
        this.sh = window.innerHeight;
        this.resizeHandler();

        this.preload();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    preload(){
        let tl = new THREE.TextureLoader();
        tl.load(depthMap, texture =>{
            this.depthTexture = texture;
            this.initWebGL();
            this.initMesh();
            // this.setGUI();
            this.enabled = true;
            if(!this.waitTransition) this.start();
        });
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();

        this.waitTransition = false;
        this.isPC = (this.sw >= this.pack.BP) ? true : false;
        this.enabled = false;
        this.mouseStage = new THREE.Vector2(0, 0);

        this.cameraZForKV = -800;
        this.cameraZForSection1 = -1100;
        this.cameraZForKVSP = -1100;
        this.cameraZForSection1SP = -1600;
        this.cameraZDef = -2900;
        this.cameraZDefSP = -4355;

        this.cameraSection1XPC = -1290;
        this.cameraSection1YPC = -760;
        this.cameraSection1ZPC = -8280;
        this.cameraSection1XSP = -1160;
        this.cameraSection1YSP = -2240;
        this.cameraSection1ZSP = -20000;

        this.cameraSection1_2XPC = 0;
        this.cameraSection1_2YPC = 360;
        this.cameraSection1_2ZPC = -3360;
        this.cameraSection1_2XSP = 110;
        this.cameraSection1_2YSP = 240;
        this.cameraSection1_2ZSP = -3400;

        this.cameraSection2XPC = 200;
        this.cameraSection2YPC = -200;
        this.cameraSection2ZPC = -2750;
        this.cameraSection2XSP = 140;
        this.cameraSection2YSP = -350;
        this.cameraSection2ZSP = -4150;

        this.currentCameraX = 0;
        this.currentCameraY = 0;
        this.currentCameraZ = (this.isPC) ? this.cameraZForKV : this.cameraZForKVSP;

        this.currentCameraXEase = this.currentCameraX;
        this.currentCameraYEase = this.currentCameraY;
        this.currentCameraZEase = this.currentCameraZ;

        this.currentSectionForScroll = 'kv';     //kv, section1, section1-2, section2, section3, section4
        this.currentSection = 'kv';     //kv, section1, section1-2, section2, section3, section4
        this.pastSection = 'kv';     //kv, section1, section1-2, section2, section3, section4

        this.SCROLL_TO_KV = 'scrollToKv';
        this.SCROLL_TO_SECTION1 = 'scrollToSection1';
        this.SCROLL_TO_SECTION1_2 = 'scrollToSection1_2';
        this.SCROLL_TO_SECTION2 = 'scrollToSection2';
        this.SCROLL_TO_SECTION3 = 'scrollToSection3';
        this.SCROLL_TO_SECTION4 = 'scrollToSection4';

        this.GET_SCROLL_PROGRESS_KV = 'getScrollProgressKv';
        this.GET_SCROLL_PROGRESS_SECTION1 = 'getScrollProgressSection1';
        this.GET_SCROLL_PROGRESS_SECTION1_2 = 'getScrollProgressSection1_2';
        this.GET_SCROLL_PROGRESS_SECTION2 = 'getScrollProgressSection2';
        this.GET_SCROLL_PROGRESS_SECTION3 = 'getScrollProgressSection3';
        this.GET_SCROLL_PROGRESS_SECTION4 = 'getScrollProgressSection4';

        this.SCROLL_TO_SERVICE_KV = 'scrollToServiceKv';
        this.SCROLL_TO_SERVICE_OVERVIEW = 'scrollToServiceOverview';
        this.SCROLL_TO_SERVICE_FEATURE = 'scrollToServiceFeature';
        this.SCROLL_TO_SERVICE_BRINGING = 'scrollToServiceBringing';
        this.SCROLL_TO_SERVICE_ROADMAP = 'scrollToServiceRoadmap';
    }

    setDom(){
        super.setDom();

        this.kv = document.querySelector('.lKv');
        this.section1 = document.querySelector('.lSection1');
        this.section1_2 = document.querySelector('.lSection1-2');
        this.section2 = document.querySelector('.lSection2');
        this.section3 = document.querySelector('.lSection3');
        this.section4 = document.querySelector('.-service');
        this.section1WordPC = document.querySelectorAll('.lSection1-word._pc');
        this.section1WordSP = document.querySelectorAll('.lSection1-word._sp');
        this.section1WordPC = this.section1WordPC[this.section1WordPC.length-1];
        this.section1WordSP = this.section1WordSP[this.section1WordSP.length-1];
        this.section1Word = (this.sw >= this.pack.BP) ? this.section1WordPC : this.section1WordSP;

        this.serviceOverview = document.querySelector('.lOverview');
        this.serviceFeature = document.querySelector('.lFeature');
        this.serviceBringing = document.querySelector('.lBringing');
        this.serviceRoadmap = document.querySelector('.lRoadmap');
    }

    initEvents(){
        super.initEvents();

        if(this.resizeEvent === 'orientationchange'){
            window.addEventListener('resize', (event) => {
                this.sw = window.innerWidth;
                this.sh = window.innerHeight;
                if(this.sw < this.pack.BP) this.sh = this.sh + 80;

                this.swh = this.sw / 2;
                this.shh = this.sh / 2;
            });
        }

        this.bindPointermoveHandler = this.pointerMoveHandler.bind(this);
        //マウスオーバーとドラッグ処理
        document.addEventListener(this.pack.hasTouch ? 'touchmove' : 'mousemove', this.bindPointermoveHandler,{passive : false});

        eventBus.on(this.SCROLL_TO_KV, () => {
            if(this.currentSection === 'kv') return;
            this.pastSection = this.currentSection;
            this.currentSection = 'kv';
            this.transit('KV');
        });

        eventBus.on(this.SCROLL_TO_SECTION1, () => {
            if(this.currentSection === 'section1') return;
            this.pastSection = this.currentSection;
            this.currentSection = 'section1';
            this.transit('section1');
        });

        eventBus.on(this.SCROLL_TO_SECTION1_2, () => {
            if(this.currentSection === 'section1-2') return;
            this.pastSection = this.currentSection;
            this.currentSection = 'section1-2';
            this.transit('section1-2');
        });

        eventBus.on(this.SCROLL_TO_SECTION2, () => {
            if(this.currentSection === 'section2') return;
            this.pastSection = this.currentSection;
            this.currentSection = 'section2';
            this.transit('section2');
        });

        eventBus.on(this.SCROLL_TO_SECTION3, () => {
            if(this.currentSection === 'section3') return;
            this.pastSection = this.currentSection;
            this.currentSection = 'section3';
            this.transit('section3');
        });

        eventBus.on(this.SCROLL_TO_SECTION4, () => {
            if(this.currentSection === 'section4') return;
            this.pastSection = this.currentSection;
            this.currentSection = 'section4';
            this.transit('section4');
        });

        eventBus.on(this.GET_SCROLL_PROGRESS_KV, (value) => {
            this.updateForScroll(this.currentSection, value.progress);
            if(this.flower) this.flower.updateForScroll(this.currentSection, value.progress);
            // trace('kv', value.progress);
        });

        eventBus.on(this.GET_SCROLL_PROGRESS_SECTION1, (value) => {
            this.updateForScroll(this.currentSection, value.progress);
            if(this.flower) this.flower.updateForScroll(this.currentSection, value.progress);
            // trace('section1', value.progress);
        });

        eventBus.on(this.GET_SCROLL_PROGRESS_SECTION1_2, (value) => {
            this.updateForScroll(this.currentSection, value.progress);
            if(this.flower) this.flower.updateForScroll(this.currentSection, value.progress);
            // trace('section1', value.progress);
        });

        eventBus.on(this.GET_SCROLL_PROGRESS_SECTION2, (value) => {
            this.updateForScroll(this.currentSection, value.progress);
            if(this.flower) this.flower.updateForScroll(this.currentSection, value.progress);
            // trace('section2', value.progress);
        });

        eventBus.on(this.GET_SCROLL_PROGRESS_SECTION3, (value) => {
            this.updateForScroll(this.currentSection, value.progress);
            if(this.flower) this.flower.updateForScroll(this.currentSection, value.progress);
            // trace('section3', value.progress);
        });

        eventBus.on(this.SCROLL_TO_SERVICE_KV, () => {
            if(this.currentSection === 'kv') return;
            this.pastSection = this.currentSection;
            this.currentSection = 'kv';
            this.transit('KV');
        });

        eventBus.on(this.SCROLL_TO_SERVICE_OVERVIEW, () => {
            if(this.currentSection === 'overview') return;
            this.pastSection = this.currentSection;
            this.currentSection = 'overview';
            this.transit('overview');
        });


        eventBus.on(this.SCROLL_TO_SERVICE_FEATURE, () => {
            if(this.currentSection === 'feature') return;
            this.pastSection = this.currentSection;
            this.currentSection = 'feature';
            this.transit('feature');
        });

        eventBus.on(this.SCROLL_TO_SERVICE_BRINGING, () => {
            if(this.currentSection === 'bringing') return;
            this.pastSection = this.currentSection;
            this.currentSection = 'bringing';
            this.transit('bringing');
        });

        eventBus.on(this.SCROLL_TO_SERVICE_ROADMAP, () => {
            if(this.currentSection === 'roadmap') return;
            this.pastSection = this.currentSection;
            this.currentSection = 'roadmap';
            this.transit('roadmap');
        });
    }

    pointerMoveHandler(event){
        let x, y;
        let w = this.sw;
        let h = this.sh;

        if(event.type.indexOf('touch') == 0) {
            let touches = event.changedTouches[0];
            x = touches.clientX;
            y = touches.clientY;
        }else{
            x = event.clientX;
            y = event.clientY;
        }

        this.mouseStage.x = (x / w * 2) - 1;
        this.mouseStage.y = (-y / h * 2) + 1;

        if(this.flower) this.flower.pointerMoveHandler(this.mouseStage);
    }

    start(){
        this.background.start();
        this.flower.start();
        this.copy.start();
        this.scanLine.start();
    }

    stop(){
        // this.canvas.remove();
        this.enabled = false;
    }

    leave(){
        this.flower.leave();
        this.copy.leave();
        this.background.leave();
        this.canvasContainer.classList.remove('white');
    }

    restart(){
        // this.canvasContainer.appendChild(this.canvas);

        this.setDom();
        this.enabled = true;
        this.currentSectionForScroll = 'kv';
        this.currentSection = 'kv';
        this.pastSection = 'kv';

        // gsap.delayedCall(3, ()=>{
            this.flower.restart();
            this.copy.restart();
        // });
    }

    //for debug
    setGUI(){
        const gui = new GUI();
        gui.width = 414;
        gui.left = 0;
        gui.domElement.style.zIndex = 2000;
        gui.close();

        const PROPS = {
            pageTransition: 'TOP',
            transition: 'KV',
            scrollProgress: 0,
            cameraX: 0,
            cameraY: 0,
            cameraZ: this.cameraZForKV,
        }

        gui.add( PROPS, 'pageTransition', [ 'TOP', 'SERVICE'] ).onChange(value => {

            eventBus.emit('leave');
            gsap.delayedCall(1, ()=>{
                if(value === 'TOP') window.sdStore.pageId = 'pIndex';
                else if(value === 'SERVICE') window.sdStore.pageId = 'pService';
                eventBus.emit('enter');
            });
        });

        gui.add( PROPS, 'transition', [ 'KV', 'section1', 'section1-2', 'section2', 'section3', 'section4'] ).onChange(value => {
            if(value === 'KV') eventBus.emit(this.SCROLL_TO_KV);
            else if(value === 'section1') eventBus.emit(this.SCROLL_TO_SECTION1);
            else if(value === 'section1-2') eventBus.emit(this.SCROLL_TO_SECTION1_2);
            else if(value === 'section2') eventBus.emit(this.SCROLL_TO_SECTION2);
            else if(value === 'section3') eventBus.emit(this.SCROLL_TO_SECTION3);
            else if(value === 'section4') eventBus.emit(this.SCROLL_TO_SECTION4);
            // this.transit(value);
        });

        gui.add( PROPS, 'scrollProgress', 0, 1 ).onChange(value => {
            let progress = value;
            if(this.currentSection === 'kv') eventBus.emit(this.GET_SCROLL_PROGRESS_KV, {progress});
            else if(this.currentSection === 'section1') eventBus.emit(this.GET_SCROLL_PROGRESS_SECTION1, {progress});
            else if(this.currentSection === 'section1-2') eventBus.emit(this.GET_SCROLL_PROGRESS_SECTION1_2, {progress});
            else if(this.currentSection === 'section2') eventBus.emit(this.GET_SCROLL_PROGRESS_SECTION2, {progress});
            else if(this.currentSection === 'section3') eventBus.emit(this.GET_SCROLL_PROGRESS_SECTION3, {progress});
            else if(this.currentSection === 'section4') eventBus.emit(this.GET_SCROLL_PROGRESS_SECTION4, {progress});
        });

        gui.add( PROPS, 'cameraX', -5000, 5000 ).onChange(value => {
            this.camera.position.x = value
        });

        gui.add( PROPS, 'cameraY', -5000, 5000 ).onChange(value => {
            this.camera.position.y = value
        });

        gui.add( PROPS, 'cameraZ', -20000, -0.1 ).onChange(value => {
            this.camera.position.z = value
        });

        this.flower.setGUI(gui);
        // this.background.setGUI(gui);
        // this.copy.setGUI(gui);
    }

    initWebGL() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : true,
            antialias : true
        });
        // this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setPixelRatio(1);
        this.renderer.setSize(this.sw, this.sh);
        // this.renderer.setClearColor(0xFFFFFF, 0.);
        // this.renderer.setClearColor(0x212032);
        // this.renderer.setClearColor(0x0000ff);
        // this.renderer.autoClear = false;

        this.scene = new THREE.Scene();

        this.fov = 15;
        this.camera = new THREE.PerspectiveCamera(this.fov, this.sw / this.sh, .1, 20000);
        this.camera.lookAt(this.scene.position);

        if(this.isPC) this.camera.position.z = this.cameraZForKV;
        else this.camera.position.z = this.cameraZForKVSP;

        // 環境光源
        this.ambientLight = new THREE.AmbientLight(0x000000);
        this.scene.add(this.ambientLight);

        // 点光源
        this.pointLight = new THREE.PointLight(0x000000);
        this.scene.add(this.pointLight);

        this.lightRotateObj3D = new THREE.Object3D();
        this.pointLight.position.set(0, 200, -300);
        this.lightRotateObj3D.add(this.pointLight);

        let sphere = new THREE.SphereGeometry( 0, 8, 8 );
        this.light = new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color:0xffffff, opacity: 0 } ) );
        this.light.position.set(this.pointLight.position.x, this.pointLight.position.y, this.pointLight.position.z);
        this.light.scale.x = this.light.scale.y = this.light.scale.z = 0.05;
        this.lightRotateObj3D.add(this.light);
        this.scene.add(this.lightRotateObj3D);

        // if(this.enabledControls) {
            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            this.controls.enabled = this.enabledControls;
        // }
    }

    initMesh(){
        this.background = new Background(this.pack);
        this.background.setBackground(this);
        this.flower = new Flower(this.pack);
        this.flower.setFlower(this);
        this.copy = new Copy(this.pack);
        this.copy.setCopy(this);
        this.scanLine = new ScanLine(this.pack);
        this.scanLine.setScanLine(this);
    }

    transit(value){
        let isTop = this.pack.isTop();

        //強制設定
        let isCutIn = false;

        if(isTop){
            if(value === 'section1-2' && this.pastSection === 'section1'){
                isCutIn = true;

                //cameraの位置
                if(this.isPC){
                    this.currentCameraX = this.currentCameraXEase = this.cameraSection1XPC;
                    this.currentCameraY = this.currentCameraYEase = this.cameraSection1YPC;
                    this.currentCameraZ = this.currentCameraZEase = this.cameraSection1ZPC;
                }else{
                    this.currentCameraX = this.currentCameraXEase = this.cameraSection1XSP;
                    this.currentCameraY = this.currentCameraYEase = this.cameraSection1YSP;
                    this.currentCameraZ = this.currentCameraZEase = this.cameraSection1ZSP;
                }

                this.camera.position.set(this.currentCameraXEase, this.currentCameraYEase, this.currentCameraZEase);
            }else if(value === 'section1' && this.pastSection === 'section1-2'){
                isCutIn = true;

                //cameraの位置
                if(this.isPC){
                    this.currentCameraX = this.currentCameraXEase = 0;
                    this.currentCameraY = this.currentCameraYEase = 0;
                    this.currentCameraZ = this.currentCameraZEase = this.cameraZForSection1;
                }else{
                    this.currentCameraX = this.currentCameraXEase = 0;
                    this.currentCameraY = this.currentCameraYEase = 0;
                    this.currentCameraZ = this.currentCameraZEase = this.currentCameraZ = this.currentCameraZEase = this.cameraZForSection1SP;
                }
                this.camera.position.set(this.currentCameraXEase, this.currentCameraYEase, this.currentCameraZEase);
            }

            this.flower.transit(value, isCutIn);
            this.background.transit(value, isCutIn);
            this.copy.transit(value);
            this.scanLine.transit(value, isCutIn);
        }else{
            if(value === 'overview' && this.pastSection === 'kv'){
                isCutIn = true;
            }else if(value === 'KV' && this.pastSection === 'overview'){
                isCutIn = true;
            }

            this.background.transit(value, isCutIn);
            this.copy.transit(value);
            this.scanLine.transit(value, isCutIn);
        }

        if(isTop){
            if(value === 'KV'){
                this.canvasContainer.classList.remove('white');
            }else if(value === 'section1'){
                this.canvasContainer.classList.remove('white');
            }else if(value === 'section1-2'){
                this.canvasContainer.classList.remove('white');
            }else if(value === 'section2'){
                this.canvasContainer.classList.add('white');
            }else if(value === 'section3'){
                this.canvasContainer.classList.remove('white');
            }
        }else{
            if(value === 'KV'){
                this.canvasContainer.classList.remove('white');
            }else if(value === 'overview'){
                this.canvasContainer.classList.remove('white');
            }else if(value === 'feature'){
                this.canvasContainer.classList.add('white');
            }else if(value === 'bringing'){
                this.canvasContainer.classList.remove('white');
            }else if(value === 'roadmap'){
                this.canvasContainer.classList.remove('white');
            }
        }



    }

    updateForScroll(currentSection, progress){
        let x = 0, y = 0, z = 0;
        let x1;
        let y1;
        let z1;
        let x2;
        let y2;
        let z2;
        if(currentSection === 'kv' || currentSection === 'section1'){
            let z1 = (this.isPC) ? this.cameraZForKV : this.cameraZForKVSP;
            let z2 = (this.isPC) ? this.cameraZForSection1 : this.cameraZForSection1SP;
            z = this.pack.lerp(z1, z2, progress);
        }else if(currentSection === 'section1-2'){
            if(this.isPC){
                x1 = this.cameraSection1XPC;
                y1 = this.cameraSection1YPC;
                z1 = this.cameraSection1ZPC;
                x2 = this.cameraSection1_2XPC;
                y2 = this.cameraSection1_2YPC;
                z2 = this.cameraSection1_2ZPC;
            }else{
                x1 = this.cameraSection1XSP;
                y1 = this.cameraSection1YSP;
                z1 = this.cameraSection1ZSP;
                x2 = this.cameraSection1_2XSP;
                y2 = this.cameraSection1_2YSP;
                z2 = this.cameraSection1_2ZSP;
            }

            x = this.pack.lerp(x1, x2, progress);
            y = this.pack.lerp(y1, y2, progress);
            z = this.pack.lerp(z1, z2, progress);
        }else if(currentSection === 'section2'){

            if(this.isPC){
                x1 = this.cameraSection1_2XPC;
                y1 = this.cameraSection1_2YPC;
                z1 = this.cameraSection1_2ZPC;
                x2 = this.cameraSection2XPC;
                y2 = this.cameraSection2YPC;
                z2 = this.cameraSection2ZPC;
            }else{
                x1 = this.cameraSection1_2XSP;
                y1 = this.cameraSection1_2YSP;
                z1 = this.cameraSection1_2ZSP;
                x2 = this.cameraSection2XSP;
                y2 = this.cameraSection2YSP;
                z2 = this.cameraSection2ZSP;
            }

            x = this.pack.lerp(x1, x2, progress);
            y = this.pack.lerp(y1, y2, progress);
            z = this.pack.lerp(z1, z2, progress);
        }else if(currentSection === 'section3'){
            x2 = 0, y2 = 0;
            if(this.isPC){
                x1 = this.cameraSection2XPC;
                y1 = this.cameraSection2YPC;
                z1 = this.cameraSection2ZPC;
                z2 = this.cameraZDef;
            }else{
                x1 = this.cameraSection2XSP;
                y1 = this.cameraSection2YSP;
                z1 = this.cameraSection2ZSP;
                z2 = this.cameraZDefSP;
            }

            if(progress > 1) progress = 1;
            x = this.pack.lerp(x1, x2, progress);
            y = this.pack.lerp(y1, y2, progress);
            z = this.pack.lerp(z1, z2, progress);
        }

        this.currentCameraX = x;
        this.currentCameraY = y;
        this.currentCameraZ = z;
    }

    scrollHandler(){

    }

    render(){
        this.flower.renderPostScene();
        this.renderer.render(this.scene, this.camera);
    }

    enterframe(){

    }

    enterframeThinOut(){
        if(!this.enabled) return;

        this.flower.enterframeThinOut();
        this.background.enterframeThinOut();
        this.copy.enterframeThinOut();
        this.scanLine.enterframeThinOut();
        this.render();

        if(this.camera){
            this.currentCameraXEase += (this.currentCameraX - this.currentCameraXEase) * .2;
            this.currentCameraYEase += (this.currentCameraY - this.currentCameraYEase) * .2;
            this.currentCameraZEase += (this.currentCameraZ - this.currentCameraZEase) * .2;

            this.camera.position.set(this.currentCameraXEase, this.currentCameraYEase, this.currentCameraZEase);
        }

        if(this.pack.isTop()){
            let kvHeight = this.kv.clientHeight;
            if(kvHeight === 0) return;
            if(!this.section1) return;

            let current = 'kv';
            let progress;
            let kvScrollableHeight = this.sh * .2;
            let kvSection1ScrollableHeight = kvHeight + this.section1.clientHeight - this.sh;
            let kvTop = this.kv.getBoundingClientRect().top;
            let section1Top = this.section1.getBoundingClientRect().top;
            progress = Math.abs(kvTop / kvSection1ScrollableHeight);

            if(section1Top < this.sh - kvScrollableHeight) {
                current = 'section1';
                let section1WordTop = this.section1Word.getBoundingClientRect().top;
                let section1WordHeight = this.section1Word.clientHeight;

                if(section1WordTop < -section1WordHeight) {
                    current = 'section1-2';
                    let section1_2Top = this.section1_2.getBoundingClientRect().top;
                    let adjustHeight = section1_2Top - section1WordTop + section1WordHeight;
                    let section1_2ScrollableHeight = adjustHeight + this.section1_2.clientHeight - this.sh;
                    let section2Top = this.section2.getBoundingClientRect().top;
                    progress = Math.abs((section1WordTop - section1WordHeight) / section1_2ScrollableHeight);
                    // trace(section1_2Top, section1WordTop + section1WordHeight, adjustHeight);

                    if(progress > 1) progress = 1;
                    if(section2Top < this.sh * .8) {
                        current = 'section2';
                        let section2ScrollableHeight = this.section2.clientHeight;
                        let section3Top = this.section3.getBoundingClientRect().top;
                        progress = Math.abs((section2Top - this.sh * .8) / section2ScrollableHeight);
                        if(progress > 1) progress = 1;

                        if(section3Top < this.shh) {
                            current = 'section3';
                            let section3ScrollableHeight = this.section3.clientHeight * .5;
                            progress = Math.abs((section3Top - this.shh) / section3ScrollableHeight);
                            // if(this.section4.getBoundingClientRect().top < 0) current = 'section4';
                        }
                    }
                }
            }

            //currentが変更されたら
            if(current != this.currentSectionForScroll){
                if(current === 'kv') eventBus.emit(this.SCROLL_TO_KV);
                else if(current === 'section1') eventBus.emit(this.SCROLL_TO_SECTION1);
                else if(current === 'section1-2') eventBus.emit(this.SCROLL_TO_SECTION1_2);
                else if(current === 'section2') eventBus.emit(this.SCROLL_TO_SECTION2);
                else if(current === 'section3') eventBus.emit(this.SCROLL_TO_SECTION3);
                else if(current === 'section4') eventBus.emit(this.SCROLL_TO_SECTION4);

                this.currentSectionForScroll = current;
            }

            if(this.currentSection === 'kv') eventBus.emit(this.GET_SCROLL_PROGRESS_KV, {progress});
            else if(this.currentSection === 'section1') eventBus.emit(this.GET_SCROLL_PROGRESS_SECTION1, {progress});
            else if(this.currentSection === 'section1-2') eventBus.emit(this.GET_SCROLL_PROGRESS_SECTION1_2, {progress});
            else if(this.currentSection === 'section2') eventBus.emit(this.GET_SCROLL_PROGRESS_SECTION2, {progress});
            else if(this.currentSection === 'section3') eventBus.emit(this.GET_SCROLL_PROGRESS_SECTION3, {progress});
        }else{
            let current = 'kv';
            if(!this.serviceOverview) return;
            let overviewTop = this.serviceOverview.getBoundingClientRect().top;
            if(overviewTop < 0) {
                current = 'overview';
                let featureTop = this.serviceFeature.getBoundingClientRect().top;
                if(featureTop < this.shh) {
                    current = 'feature';
                    let bringingTop = this.serviceBringing.getBoundingClientRect().top;
                    if(bringingTop < this.shh) {
                        current = 'bringing';
                        let roadmapTop = this.serviceRoadmap.getBoundingClientRect().top;
                        if(roadmapTop < this.shh) {
                            current = 'roadmap';
                        }
                    }
                }
            }


            //currentが変更されたら
            if(current != this.currentSectionForScroll){
                if(current === 'kv') eventBus.emit(this.SCROLL_TO_SERVICE_KV);
                else if(current === 'overview') eventBus.emit(this.SCROLL_TO_SERVICE_OVERVIEW);
                else if(current === 'feature') eventBus.emit(this.SCROLL_TO_SERVICE_FEATURE);
                else if(current === 'bringing') eventBus.emit(this.SCROLL_TO_SERVICE_BRINGING);
                else if(current === 'roadmap') eventBus.emit(this.SCROLL_TO_SERVICE_ROADMAP);

                this.currentSectionForScroll = current;
            }
        }
    }

    executeResize() {
        super.executeResize();
        document.documentElement.style.setProperty('--height', `${this.sh}px`);
        this.section1Word = (this.sw >= this.pack.BP) ? this.section1WordPC : this.section1WordSP;
        if(!this.canvas) return;

        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        if(!this.camera) return;

        this.camera.aspect = this.sw / this.sh;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.sw, this.sh);
    }
}