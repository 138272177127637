import "@babel/polyfill";

import Config from "./Config";

require('./libs/daijima/trace.js');
require('./libs/daijima/requestanimationframe.js');

let packName = "HANA";
let pack = new Config(window, packName);

import "../scss/common.scss";
//CSS Modulesとして使用する場合
// import * as styles from "../scss/common.scss";

import Common from './Common';

new Common(pack);