import TemplateContents from './TemplateContents';

import Visual from './Visual';

export default class extends TemplateContents{
    constructor(param){
        super(param);
        this.pack.common = this;
    }

    init() {
        super.init();
    }

    addScrollTarget(target){
        this.scrollTarget = target;
    }

    removeScrollTarget(){
        this.scrollTarget = undefined;
    }

    addEnterframeTarget(target){
        this.enterframeTarget = target;
    }

    removeEnterframeTarget(){
        this.enterframeTarget = undefined;
    }

    setVars(){
        super.setVars();
        this.enterframeID;
        this.enterframeIgnoreCnt = 0;

        this.pastPageYOffset = 0;
        this.scrollYOffset = 0;
        this.pastScrollYOffset = 0;

        this.UPDATE_LOAD_COEFF = 0.5;
        this.FPS = 30;
        this.targetInterval = 1000 / this.FPS;
        this.prevTime = Date.now() - this.targetInterval;
    }

    setDom(){
        super.setDom();
        this.cBackgroundCover = document.querySelector('.cBackgroundCover');
        this.cNextContent = document.querySelector('.cNextContent');
        this.footer = document.querySelector('.cFooter');
    }

    initEvents(){
        super.initEvents();

        this.resizeEvent = navigator.userAgent.match(/(iPhone|iPod|iPad)/) ? 'orientationchange' : 'resize';

        window.addEventListener("load", this.loadedHandler.bind(this));
        window.addEventListener("DOMContentLoaded", this.DOMContentloadedHandler.bind(this));

        window.addEventListener(this.resizeEvent, this.resizeHandler.bind(this), false);
        window.addEventListener('scroll', this.scrollHandler.bind(this),{passive: false});


        eventBus.on('leave', (pageId, trigger) => {
            // trace('------leave------');
            // 遷移時に離れたページid
            // console.log(pageId);
            // 遷移のためにクリックされた要素
            // console.log(trigger);
            this.pack.visual.gl.leave();
        });

        eventBus.on('enter', (pageId, pageIdPrev) => {
            // trace('------enter------');
            // 遷移後に表示するページid
            // console.log(pageId);
            // 遷移時に離れたページid
            // console.log(pageIdPrev);

            this.setDom();
            this.pack.visual.gl.restart();
        });

        eventBus.on('enterCompleted', (pageId, pageIdPrev) => {
            // trace('------enterCompleted------');
            // 遷移後に表示するページid
            // console.log(pageId)
            // 遷移時に離れたページid
            // console.log(pageIdPrev)
        })

        eventBus.on('destroy', () => {
            // 処理を削除、止める記述（removeEventListener など）
            // trace('------destroy------');
            // trace('window.sdStore.pageId');

            this.pack.visual.gl.stop();
        });

        this.resizeHandler();
        this.enterframe();
    }

    DOMContentloadedHandler(event){
        this.initContainer();
    }

    loadedHandler(event){

    }

    initContainer() {

        this.pack.visual = new Visual(this.pack);
        this.setCurrent();
    }

    setCurrent(){
        // this.pack.header.setCurrent();
    }

    start(){

    }

    scrollHandler(){
        this.pack.pageYOffset = window.pageYOffset;
        if (this.scrollTarget) this.scrollTarget.scrollHandler();
    }


    enterframe(){
        this.enterframeID = window.requestAnimationFrame(this.enterframe.bind(this));

        if(this.enterframeTarget && this.enterframeTarget.enterframe) this.enterframeTarget.enterframe();

        let currentTime = Date.now();
        let updated = false;
        while (currentTime - this.prevTime > this.targetInterval) {

            updated = true;
            this.prevTime += this.targetInterval;
            const now = Date.now();
            const updateTime = now - currentTime;
            if (updateTime > this.targetInterval * this.UPDATE_LOAD_COEFF) {
                // overloaded
                if (this.prevTime < now - this.targetInterval) {
                    // do not accumulate too much
                    this.prevTime = now - this.targetInterval;
                }
                break;
            }
        }

        if (updated) {
            if(this.enterframeTarget) this.enterframeTarget.enterframeThinOut();
            this.enterframeThinOut();
        }

        if(this.enterframeIgnoreCnt % 90 === 0){
            //間引き処理(1 execution per 90 frames)
            this.resizeHandler();
        }

        //前回のスクロール位置（全ての処理が終わってから代入）
        this.pastPageYOffset = this.pack.pastPageYOffset = this.pack.pageYOffset;

        this.enterframeIgnoreCnt++;
    }

    enterframeThinOut(){
        if(this.cNextContent){
            //top
            let top = this.cNextContent.getBoundingClientRect().top;
            if(top < this.shh){
                if(!this.cBackgroundCover.classList.contains('show')) this.cBackgroundCover.classList.add('show');
            }else{
                if(this.cBackgroundCover.classList.contains('show')) this.cBackgroundCover.classList.remove('show');
            }
        }else{
            //top以外
            let top = this.footer.getBoundingClientRect().top;
            if(top < this.shh){
                if(!this.cBackgroundCover.classList.contains('show')) this.cBackgroundCover.classList.add('show');
            }else{
                if(this.cBackgroundCover.classList.contains('show')) this.cBackgroundCover.classList.remove('show');
            }
        }
    }

    executeResize() {
        super.executeResize();
    }
}