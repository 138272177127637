import TemplateContents from './TemplateContents';

import RTTSetUp from './RTTSetUp';
// import GUI from 'lil-gui';
import * as THREE from 'three';

import fs_dilate from './shaders/fs_dilate.glsl';
import fs_erode from './shaders/fs_erode.glsl';
import fs_main from './shaders/fs_main.glsl';
import vs_main from './shaders/vs_main.glsl';
import vs_rt from './shaders/vs_rt.glsl';

import normalMap from '../img/top/flat.png';
import diffuseMap from '../img/top/img_flower_p.jpg';

import vs_glitch from "./shaders/vs_flower_glitch.glsl";
import fs_glitch from "./shaders/fs_flower_glitch.glsl";

export default class extends TemplateContents{
    constructor(param){
        super(param);

    }

    init() {
        super.init();
    }

    reset(){
        super.reset();

        this.setVars();
    }

    destruct(){
        super.destruct();
    }

    setFlower(target){
        this.scene = target.scene;
        this.renderer = target.renderer;
        this.camera = target.camera;
        this.pointLight = target.pointLight;
        this.pointLight.color = new THREE.Color(this.pointLightColor);
        this.ambientLight = target.ambientLight;
        this.lightRotateObj3D = target.lightRotateObj3D;
        this.light = target.light;
        this.depthTexture = target.depthTexture;

        this.initRTT();
        this.initMesh();
    }

    setVars(){
        super.setVars();
        this.enabled = false;
        this.bumpScale = 0.0;
        this.erode = 0.029;
        this.dilate = 0.05;
        this.loopSteps = 100;
        this.DEFAULT_SCALE = 190;
        this.scale = this.DEFAULT_SCALE;

        this.diffuse = 1.0;
        this.ambient = 0.0;
        this.pointLightColor = 0x404040;
        this.shininess = 0.0;
        this.specular = 0.0;
        this.lightRotate = 0.0;

        this.flowerRotX = 75.6;
        this.flowerRotY = 5.04;
        this.flowerRotZ = -90;

        this.flowerOpacity = 1.0;
        this.glitchStrength = 2.5;
        this.grayAmount = 0.0;

        this.RTTs = {};
        this.textureMats;

        this.point = new THREE.Vector2(0, 0);
        this.pastPoint = new THREE.Vector2(0, 0);
        this.easePoint = new THREE.Vector2(0, 0);
        this.pointDif = 0;
        this.easePointDif = 0;

        this.enabledHueShift = false;
        this.hueShiftCnt = 0;
        this.hueShiftAmount = 0;
        this.pointLightHueShiftAmount = 0.94;

        this.completedGlitchEffect = false;
        this.movedToSection1 = false;
    }

    setDom(){
        super.setDom();
    }

    initEvents(){
        super.initEvents();
    }

    pointerMoveHandler(point){
        this.point = point;
    }

    //for debug
    setGUI(gui){
        const PROPS = {
            bumpScale: this.bumpScale,
            erode: this.erode,
            dilate: this.dilate,
            steps: this.loopSteps,
            scale: this.scale,
            diffuse: this.diffuse,
            ambient: this.ambient,
            pointLightColor: this.pointLightColor,
            shininess: this.shininess,
            specular: this.specular,
            lightRotate: this.lightRotate,
            pointLightHueShiftAmount: this.pointLightHueShiftAmount,
            flowerRotX: this.flowerRotX,
            flowerRotY: this.flowerRotY,
            flowerRotZ: this.flowerRotZ,
            flowerPosX: 0,
            flowerPosY: 0,
            flowerPosZ: 0,
        };

        gui.add(PROPS, 'bumpScale', 0, 100.0).onChange(value => {
            this.setMatUniform('bumpScale', value);
            this.render();
        });

        gui.add(PROPS, 'erode', 0, 0.05).onChange(value =>{
            if (value == 0.05) value = 10;
            this.setTextureMatUniform('u_erode', value);
            this.prepTextures();
        });

        gui.add(PROPS, 'dilate', 0, 0.05).onChange(value =>{
            if (value == 0.05) value = 10;
            this.setTextureMatUniform('u_dilate', value);
            this.prepTextures();
        });

        gui.add(PROPS, 'steps', 0, 100, 1).onChange(value => {
            this.loopSteps = value;
            this.prepTextures();
        });

        gui.add(PROPS, 'scale', 0, 300.0).onChange(value => {
            this.setMatUniform('uDisplacementPostScale', value);
            this.render();
        });

        gui.add(PROPS, 'diffuse', 0, 1.0).onChange(value => {
            this.setMatUniform('tDiffuseOpacity', value);
            this.render();
        });

        gui.add(PROPS, 'ambient', 0, 1.0).onChange(value => {
            this.setMatUniform('uAmbientLightColor', new THREE.Color().setRGB(value, value, value));
            this.render();
        });

        gui.addColor(PROPS, 'pointLightColor').onChange(value => {
            this.setMatUniform('uPointLightColor', new THREE.Color(value));
            this.render();
        });

        gui.add(PROPS, 'shininess', 0, 250.0).onChange(value => {
            this.setMatUniform('shininess', value);
            this.render();
        });

        gui.add(PROPS, 'specular', 0, 10.0).onChange(value => {
            this.setMatUniform('specular', new THREE.Color().setRGB(value, value, value));
            this.render();
        });

        gui.add(PROPS, 'lightRotate', 0, 1.0).onChange(value =>{
            value = value * 360;
            let rad = this.pack.d2r(value);
            this.lightRotateObj3D.rotation.y = rad;
            this.setMatUniform('uPointLightPos', new THREE.Vector3().setFromMatrixPosition( this.light.matrixWorld ));
            this.render();
        });

        gui.add(PROPS, 'pointLightHueShiftAmount', 0, 1.0).onChange(value =>{
            this.setMatUniform('pointLightHueShiftAmount', value);
            this.render();
        });

        gui.add(PROPS, 'flowerRotX', -360, 360).onChange(value => {
            this.flowerMesh.rotation.x = this.pack.d2r(value);
        });

        gui.add(PROPS, 'flowerRotY', -360, 360).onChange(value => {
            this.flowerMesh.rotation.y = this.pack.d2r(value);
        });

        gui.add(PROPS, 'flowerRotZ', -360, 360).onChange(value => {
            this.flowerMesh.rotation.z = this.pack.d2r(value);
        });

        gui.add(PROPS, 'flowerPosX', -1000, 1000).onChange(value => {
            this.flowerMesh.position.x = value;
        });

        gui.add(PROPS, 'flowerPosY', -1000, 1000).onChange(value => {
            this.flowerMesh.position.y = value;
        });

        gui.add(PROPS, 'flowerPosZ', -1000, 1000).onChange(value => {
            this.flowerMesh.position.z = value;
        });
    }

    initRTT(){
        this.rtt = new RTTSetUp(this.depthTexture, vs_rt, fs_dilate, this);
        this.addRTT('flower', this.rtt);
    }

    initMesh(){
        let debugs = this.debugs = new THREE.Object3D();
        this.scene.add(debugs);

        let uniforms = {
            time: {type:'f', value: 0.0},
            pointDif: {type:'f', value: 0.0},
            tNormal: {type:'t', value:new THREE.TextureLoader().load(normalMap)},
            diffuse: {type:'c', value:new THREE.Color().setHex(0xffffff)},
            specular: {type:'c', value:new THREE.Color().setHex(0xffffff)},
            ambient: {type:'c', value:new THREE.Color().setHex(0xffffff)},
            shininess: {type:'f', value:this.shininess},
            enableDiffuse: {type:'i', value:1},
            tDiffuse: {type:'t', value:new THREE.TextureLoader().load(diffuseMap)},
            tDisplacement: { type: 't', value: this.rtt.texture2.texture },
            tDiffuseOpacity: { type: 'f', value: 1 },
            tDiffuse2Opacity: { type: 'f', value: 0 },
            uPointLightPos: { type: 'v3', value: this.pointLight.position },
            uPointLightColor: { type: 'c', value: new THREE.Color( this.pointLight.color ) },
            uAmbientLightColor: { type: 'c', value: new THREE.Color( this.ambientLight.color ) },
            matrightBottom: { type: 'v2', value: new THREE.Vector2( 180.0, -90.0 ) },
            matleftTop: { type: 'v2', value: new THREE.Vector2( -180.0, 90.0 ) },
            sphereRadius: { type: 'f', value: 100.0 },
            mixAmount: { type: 'f', value: 1.0 },
            uDisplacementScale: { type: 'f', value: 100 },
            uDisplacementPostScale: { type: 'f', value: this.scale },
            bumpScale: { type: 'f', value: this.bumpScale },
            opacity: { type: 'f', value: this.flowerOpacity },
            uNormalOffset: { type: 'v2', value: new THREE.Vector2( 1.0, 1.0 ) },
            distortionAmount: { type: 'f', value: 0.1 },
            grayAmount: { type: 'f', value: this.grayAmount },
            hueShiftAmount: { type: 'b', value: this.hueShiftAmount },
            pointLightHueShiftAmount: { type: 'b', value: this.pointLightHueShiftAmount },
        };

        let material = this.material = new THREE.ShaderMaterial( {
            uniforms: uniforms,
            vertexShader: vs_main,
            fragmentShader: fs_main,
            transparent: true,
        } );

        /*
            Safariでエラーが出たので以下の1行を追記
            ref: https://stackoverflow.com/questions/67453679/issue-on-using-an-extension-for-glsl-when-updating-three-js
        */
        material.extensions.derivatives = true;

        this.flowerGeo = new THREE.PlaneGeometry(10, 10, 257, 129);
        this.flowerMesh = new THREE.Mesh( this.flowerGeo, material);
        // this.scene.add(this.flowerMesh);


        this.setPostScene();

        this.flowerMesh.rotation.x = this.pack.d2r(this.flowerRotX);
        this.flowerMesh.rotation.y = this.pack.d2r(this.flowerRotY);
        this.flowerMesh.rotation.z = this.pack.d2r(this.flowerRotZ);

        /*
                //for debug
                let myDbgGeo = new THREE.PlaneGeometry( 50, 50, 1, 1 );
                let myDbgMat = new THREE.MeshBasicMaterial({ color: 0xffffff, map: this.rtt.texture.texture, side:THREE.DoubleSide });
                let myDbg = new THREE.Mesh( myDbgGeo, myDbgMat );
                myDbg.position.set(140, 60, 0);
                myDbg.rotation.y = this.pack.d2r(180);
                debugs.add( myDbg );
                // myDbg.visible = false;

                let myDbgGeo2 = new THREE.PlaneGeometry( 50, 50, 1, 1 );
                let myDbgMat2 = new THREE.MeshBasicMaterial({ color: 0xffffff, map: this.rtt.texture2.texture, side:THREE.DoubleSide });
                let myDbg2 = new THREE.Mesh( myDbgGeo2, myDbgMat2 );
                myDbg2.position.set(140, 0, 0);
                myDbg2.rotation.y = this.pack.d2r(180);
                debugs.add( myDbg2 );
                // myDbg2.visible = false;
        */

        for (let i in this.RTTs) {
            this.rtt = this.RTTs[i];
            this.prepTextures();
        }
    }

    setPostScene(){
        this.sizeW = this.sizeH = 1024;
        this.postScene = new THREE.Scene();
        this.postScene.add(this.flowerMesh);
        // this.scene.add(this.flowerMesh);

        let renderTargetParams = {
            minFilter: THREE.LinearFilter,
            stencilBuffer: false,
            depthBuffer: true,
        };
        this.postCamera = new THREE.OrthographicCamera( this.sizeW / - 2, this.sizeW / 2, this.sizeH / 2, this.sizeH / - 2, -10000, 10000 );
        this.renderTarget = new THREE.WebGLRenderTarget(this.sizeW, this.sizeH, renderTargetParams);

        // this.renderer.setRenderTarget(this.renderTarget);
        // this.renderer.render(this.postScene, this.postCamera);
        // this.renderer.setRenderTarget(null);

        let geometry = new THREE.PlaneGeometry( 2, 2);
        let material = this.postMaterial = new THREE.ShaderMaterial({
            uniforms: {
                resolution: {type:'v2', value: new THREE.Vector2(this.sw, this.sh)},
                time: {type:'f', value: 0.0},
                uTexture: { value: this.renderTarget.texture },
                glitchStrength: {type:'f', value: this.glitchStrength},
                // uTexture: { value: new THREE.TextureLoader().load(diffuseMap) },
            },
            // side: THREE.DoubleSide,
            vertexShader: vs_glitch,
            fragmentShader: fs_glitch,
            transparent: true,
            depthTest: true, // no depth tes
            depthWrite: true,
            // alphaToCoverage: true,
        });
        material.needsUpdate = true;
        let plane = this.postPlane = new THREE.Mesh( geometry, material );
        plane.renderOrder = -1; // rendering first
        // this.scene.add( plane );
    }

    addRTT(name, texture){
        this.RTTs[name] = texture;
    }

    prepTextures(){
        let rtt = this.rtt;

        this.textureMats = [rtt.textureMat, rtt.textureMat2];

        // firstShader = fs_dilate, secondShader = fs_erode;
        let firstShader = fs_erode, secondShader = fs_dilate;
        // let firstShader = fragment, secondShader = fragment;

        // set first shader
        rtt.textureMat.fragmentShader = firstShader;
        rtt.textureMat.needsUpdate = true;

        rtt.textureMat2.fragmentShader = secondShader;
        rtt.textureMat2.needsUpdate = true;

        // initialize first RTT FBO's colorMap with the source image
        rtt.textureMat.uniforms.colorMap.value = rtt.image;

        // render first FBO with erode shader
        // this.renderer.render(rtt.scene, rtt.camera, rtt.texture, false);
        this.renderer.setRenderTarget(rtt.texture);
        this.renderer.render(rtt.scene, rtt.camera);
        this.renderer.setRenderTarget(null);


        // rtt.textureMat.uniforms.colorMap.value = rtt.texture2;
        rtt.textureMat.uniforms.colorMap.value = rtt.texture2.texture;

        // while ( myRTT.textureMat.uniforms.u_unchanged == 0.0 ) {
        // would be nice to have some kind of switch that turned the loop off
        // when there was no difference detected between the two FBOs.
        // I suppose I'd need a third shader to do a diff...
        for( let i = 0, len = this.loopSteps; i < len; i++ ) {
            this.calculate(rtt);
        }

        // trace(rtt.texture, rtt.texture2);

        // switch shaders
        rtt.textureMat.fragmentShader = secondShader;
        rtt.textureMat.needsUpdate = true;

        rtt.textureMat2.fragmentShader = secondShader;
        rtt.textureMat2.needsUpdate = true;

        for( let i = 0, len = this.loopSteps; i < len; i++ ) {
            this.calculate(rtt);
        }

        this.render();
    }

    calculate(rtt){
        // render second FBO, based on first FBO
        // this.renderer.render( rtt.scene2, rtt.camera2, rtt.texture2, false );
        this.renderer.setRenderTarget(rtt.texture2);
        this.renderer.render( rtt.scene2, rtt.camera2);
        // render first FBO, based on second FBO
        // this.renderer.render( rtt.scene, rtt.camera, rtt.texture, false );
        this.renderer.setRenderTarget(rtt.texture);
        this.renderer.render( rtt.scene, rtt.camera );
        this.renderer.setRenderTarget(null);

    }

    setMatUniform(name, value){
        this.material.uniforms[name].value = value;
    }

    setTextureMatUniform(name, value) {
        for (let mat in this.textureMats) {
            this.textureMats[mat].uniforms[name].value = value;
        }
    }

    leave(){
        let ease = 'quart.out';
        gsap.killTweensOf(this);
        gsap.to(this, .5, {flowerOpacity:0, ease:ease, onUpdate:()=>{
                this.setMatUniform('opacity', this.flowerOpacity);
            }, onComplete:()=>{
                this.scene.remove(this.postPlane);
                this.enabled = false;
            }});
    }

    start(isTransition){
        if(!this.pack.isTop()) return;

        this.enabled = true;
        let ease = 'quart.out';
        let delay = isTransition ? 1.8 : 2.8;
        gsap.delayedCall(delay, ()=>{
            if(!this.movedToSection1) {
                this.scene.add( this.postPlane );
            }
            gsap.to(this, 0.7, {glitchStrength:.7, ease:ease, onUpdate:()=>{
                    this.postMaterial.uniforms.glitchStrength.value = this.glitchStrength;
                }});
            gsap.delayedCall(.5, ()=>{
                if(!this.movedToSection1) this.scene.remove(this.postPlane);
                this.scene.add(this.flowerMesh);
                this.completedGlitchEffect = true;
            });
        });
    }

    restart(){
        this.movedToSection1 = false;
        this.flowerOpacity = 1.0;
        this.glitchStrength = 2.5;
        this.completedGlitchEffect = false;
        this.enabledHueShift = false;
        this.hueShiftCnt = 0;
        this.hueShiftAmount = 0;
        this.setMatUniform('hueShiftAmount', this.hueShiftAmount);

        this.postMaterial.uniforms.glitchStrength.value = this.glitchStrength;
        this.setMatUniform('opacity', this.flowerOpacity);
        this.scene.remove(this.flowerMesh);
        this.postScene.add(this.flowerMesh);

        this.render();
        this.start(true);
    }

    transit(value, cutIn){
        if(!this.pack.isTop()) return;

        let ease = 'quart.inOut';
        let ease2 = 'quart.out';
        let dr1 = 1;
        let dr2 = 1.7;

        gsap.killTweensOf(this);
        if(value === 'KV'){
            this.enabledHueShift = false;
            this.hueShiftCnt = 0;
            gsap.to(this, dr1, {hueShiftAmount:0, ambient:0, ease:ease, onUpdate:()=>{
                    this.setMatUniform('uAmbientLightColor', new THREE.Color().setRGB(this.ambient, this.ambient, this.ambient));
                    this.setMatUniform('hueShiftAmount', this.hueShiftAmount);
                }});

        }else if(value === 'section1'){
            this.movedToSection1 = true;
            this.enabledHueShift = false;
            this.hueShiftCnt = 0;

            //強制アップデートここから
            let scale = this.DEFAULT_SCALE;
            let grayAmount = 0;
            let diffuse = 1;
            this.setMatUniform('uDisplacementPostScale', scale);
            this.setMatUniform('grayAmount', grayAmount);
            this.setMatUniform('tDiffuseOpacity', diffuse);
            this.pack.gl.render();
            //強制アップデートここまで
        }else if(value === 'section1-2'){
            if(!this.completedGlitchEffect) this.scene.add(this.flowerMesh);

            this.enabledHueShift = false;
            this.hueShiftCnt = 0;
            gsap.to(this, dr2, {hueShiftAmount:0, ambient:0, ease:ease, onUpdate:()=>{
                    this.setMatUniform('uAmbientLightColor', new THREE.Color().setRGB(this.ambient, this.ambient, this.ambient));
                    this.setMatUniform('hueShiftAmount', this.hueShiftAmount);
                }});
            //強制アップデートここから
            let scale = 0;
            let grayAmount = 1;
            let diffuse = .4;
            this.setMatUniform('uDisplacementPostScale', scale);
            this.setMatUniform('grayAmount', grayAmount);
            this.setMatUniform('tDiffuseOpacity', diffuse);
            this.pack.gl.render();
            //強制アップデートここまで
        }else if(value === 'section2'){
            this.enabledHueShift = false;
            this.hueShiftCnt = 0;
            gsap.to(this, dr2, {hueShiftAmount:0, ambient:.28, ease:ease, onUpdate:()=>{
                    this.setMatUniform('uAmbientLightColor', new THREE.Color().setRGB(this.ambient, this.ambient, this.ambient));
                    this.setMatUniform('hueShiftAmount', this.hueShiftAmount);
                }});
        }else if(value === 'section3'){
            this.enabledHueShift = true;
            gsap.to(this, dr2, {ambient:0, ease:ease2, onUpdate:()=>{
                    this.setMatUniform('uAmbientLightColor', new THREE.Color().setRGB(this.ambient, this.ambient, this.ambient));
                }});
        }
    }

    updateForScroll(currentSection, progress){
        if(currentSection === 'kv' || currentSection === 'section1'){
            let scale = this.DEFAULT_SCALE;
            let grayAmount = 0;
            let diffuse = 1;
            this.setMatUniform('uDisplacementPostScale', scale);
            this.setMatUniform('grayAmount', grayAmount);
            this.setMatUniform('tDiffuseOpacity', diffuse);
        }else if(currentSection === 'section1-2'){
            let scale = 0;
            let grayAmount = 1;
            let diffuse = .4;
            this.setMatUniform('uDisplacementPostScale', scale);
            this.setMatUniform('grayAmount', grayAmount);
            this.setMatUniform('tDiffuseOpacity', diffuse);
        }else if(currentSection === 'section2'){
            let scale = this.pack.lerp(0, 85, progress);
            let grayAmount = this.pack.lerp(1, 1, progress);
            let diffuse = this.pack.lerp(.4, 1.2, progress);
            this.setMatUniform('uDisplacementPostScale', scale);
            this.setMatUniform('grayAmount', grayAmount);
            this.setMatUniform('tDiffuseOpacity', diffuse);
        }else if(currentSection === 'section3'){
            let scale = this.pack.lerp(85, this.DEFAULT_SCALE, progress);
            let grayAmount = this.pack.lerp(1, 0, progress);
            let diffuse = this.pack.lerp(1.2, 1, progress);
            this.setMatUniform('uDisplacementPostScale', scale);
            this.setMatUniform('grayAmount', grayAmount);
            this.setMatUniform('tDiffuseOpacity', diffuse);
        }
    }

    scrollHandler(){

    }

    render(){
        this.renderer.render(this.scene, this.camera);
    }

    renderPostScene(){
        this.renderer.setRenderTarget(this.renderTarget);
        this.renderer.render(this.postScene, this.camera);
        this.renderer.setRenderTarget(null);
    }

    updateLightRotate(){
        let x = this.easePoint.x * 360;
        let y = this.easePoint.y * 360;
        let radX = this.pack.d2r(x);
        let radY = this.pack.d2r(y);
        this.lightRotateObj3D.rotation.x = radY;
        this.lightRotateObj3D.rotation.y = radX;
        this.setMatUniform('uPointLightPos', new THREE.Vector3().setFromMatrixPosition( this.light.matrixWorld ));
    }

    updateFlowerDistortion(){
        this.flowerMesh.rotation.x = this.pack.d2r(this.flowerRotX) - this.easePoint.y * .75;
        this.flowerMesh.rotation.z = this.pack.d2r(this.flowerRotZ) + this.easePoint.x * .75;

        this.setMatUniform('pointDif', this.easePointDif * 30);
    }

    enterframe(){

    }

    enterframeThinOut(){
        if(!this.enabled) return;

        this.postMaterial.uniforms.time.value += .02125;
        this.material.uniforms.time.value += .025;

        this.easePoint.x += (this.point.x - this.easePoint.x) * .1;
        this.easePoint.y += (this.point.y - this.easePoint.y) * .1;

        let difX = this.point.x - this.pastPoint.x;
        let difY = this.point.y - this.pastPoint.y;
        if(Math.abs(difX) >= Math.abs(difY)) this.pointDif = difX;
        else this.pointDif = difY;

        if(this.pointDif >= 0 && this.pointDif < .02) this.pointDif = .02;
        this.easePointDif += (this.pointDif - this.easePointDif) * .05;

        this.pastPoint.x = this.point.x;
        this.pastPoint.y = this.point.y;

        this.updateLightRotate();
        this.updateFlowerDistortion();

        if(this.enabledHueShift){
            this.hueShiftCnt += .15;
            this.hueShiftAmount = this.hueShiftCnt % 10 * .1;
        }
        this.setMatUniform('hueShiftAmount', this.hueShiftAmount);
    }

    executeResize() {
        super.executeResize();
    }
}